/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "./stickyfooter.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="site-container">
        <Header siteTitle={data.site.siteMetadata.title}/>
        <main className="site-growing-content">{children}</main>
        <footer style={{
          paddingTop: `1.45rem`,
          paddingLeft: `1.0875rem`,
          marginBottom: `0.5rem`
        }}>
          © {"Testicle Simulator " + new Date().getFullYear()}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
